<script setup lang="ts">
import { useActivity } from '~/composables/useActivity'

const { states, showTip, showRule, activityConfig } = useActivity()
</script>

<template>
  <!-- 领取中弹窗 -->
  <AModal
    v-model:open="states.isLoading"
    :footer="null"
    destroyOnClose
    :closable="false"
    :maskClosable="false"
    wrapClassName="ant-cover__basic-modal"
    :width="450"
  >
    <div class="connecting text-center">
      <i class="icon i-local:loding animate-spin text-70px mb-37px" />
      <p class="text-24px text-text">{{ $t('iWb8vgdWgrj6alZvKjq') }}</p>
    </div>
  </AModal>

  <!-- 非imtoken钱包提示弹窗 -->
  <AModal
    v-model:open="showTip.is"
    :footer="null"
    destroyOnClose
    :closable="true"
    :maskClosable="false"
    wrapClassName="ant-cover__basic-modal"
    :width="500"
    centered
    :title="$t('mxo0cnGrkxXuDuAchSx')"
    @cancel="showTip.setFalse()"
  >
    <div class="mx-auto mt-[32px]">
      <img class="block m-[0_auto] w-[70px] h-[70px]" src="@/assets/imgs/imToken-logo.svg">
      <I18nT
        class="text-[16px] text-center mt-[24px] mb-[20px] text-[#8098bf]"
        keypath="97fQuo7BbglG0zdoOO9" tag="p"
      >
        <template #name>
          <a href="https://token.im/" target="_blank" data-test-id="4ulU">imToken</a>
        </template>
      </I18nT>
      <AButton class="comfirm-btn flex-center! " target="_blank" href="https://token.im/download">
        {{ $t('je146bIYaIw95pba1Kqso') }}
      </AButton>
    </div>
  </AModal>

  <!-- 活动规则 -->
  <AModal
    v-model:open="showRule.is"
    :footer="null"
    destroyOnClose
    :closable="true"
    :maskClosable="false"
    wrapClassName="ant-cover__basic-modal"
    :width="500"
    centered
    :title="$t('qkQeCJhdb76X0hWvNyLx')"
    @cancel="showRule.setFalse()"
  >
    <div class="mt-26px lt-mobilel:text-14px">
      <ul class="lh-24px text-[#e2e8f2]">
        <li>{{ $t('aN5gaehMbTuUvld7o1_6W', { limit: (activityConfig?.limit ?? 500) }) }}</li>
        <li>{{ $t('nMrKCwFjHto6jRPfDxWEt') }}</li>
        <li>{{ $t('aEyv4pNdWj1x19TcCoGpQ') }}</li>
        <li>{{ $t('ytBty9x_7DhD6AiQqmsaz') }}</li>
        <li>{{ $t('bi_7yMxfSzCz0BdPbBgr8', { most: activityConfig?.receive_limit ?? 15, times: (activityConfig?.receive_limit ?? 15) - (activityConfig?.received ?? 0) }) }}</li>
        <li>{{ $t('cySQawRjFbMs3PIpTkIg') }}</li>
      </ul>
    </div>
  </AModal>
</template>
