import { useMutation, useQuery } from '@tanstack/vue-query'
import { createInjectionState, reactiveComputed } from '@vueuse/core'
import { imtoken } from '~/utils/imtoken'

// 逻辑封装
export const [useActivityProvider, useActivity] = createInjectionState(() => {
  const { account, isConnected, refetch } = tronComp.useAccountCurrent()

  const { config } = useAppState()

  const pagination = reactive({
    page: 1,
    page_size: 30,
  })

  const enabled = computed(() => !!config?.value?.activity.free_order)
  const enabledConfig = computed(() => {
    return config?.value?.activity.free_order !== 0 && toValue(isConnected)
  })

  // 获取领取记录列表
  const receiveLog = useQuery({
    queryKey: [apis.activityReceiveLog.id],
    enabled,
    queryFn: () => apis.activityReceiveLog(pagination),
  })

  // 获取活动配置
  const activityConfigQuerier = useQuery({
    queryKey: [apis.activityConfig, () => account.address],
    enabled: enabledConfig,
    queryFn: () => apis.activityConfig({ trx_address: account.address }),
  })

  const mutaion = useMutation({
    mutationKey: [apis.activityCreateFreeOrder.id],
    mutationFn: apis.activityCreateFreeOrder,
  })

  const showTip = useBoolean(false)
  const showRule = useBoolean(false)

  const states = reactiveComputed(() => {
    return {
      showActivity: (config?.value?.activity?.free_order ?? 0) > 0,
      isLoading: mutaion.isLoading,
      isSuccess: mutaion.isSuccess,
      freeOrder: (config?.value?.activity?.free_order ?? 0),
    }
  })

  const receiveList = computed(() => receiveLog?.data?.value?.list)
  const isLoading = computed(() => receiveLog?.isLoading.value)
  const activityConfig = computed(() => activityConfigQuerier.data.value)
  const onReceive = async () => {
    if (!imtoken.isImToken()) {
      showTip.setTrue()
      return
    }

    if (!isConnected.value && !(window as any)?.tronWeb) {
      globalModal.ConnectWallet?.open()
      return
    }

    mutaion.mutateAsync({
      requestBody: {
        pay_address: account.address,
      },
    }).then(async () => {
      aMessage.success($t('ocIgqMIzD2R3ZkGKoqt'))
      activityConfigQuerier.refetch()
      setTimeout(async () => {
        await refetch()
      }, 2000)
    })
  }
  const onRule = () => {
    console.log(activityConfig, 'activityConfig---------')
    showRule.setTrue()
  }

  return {
    states,
    showTip,
    showRule,
    onReceive,
    onRule,
    receiveList,
    activityConfig,
    enabled,
  }
})
