<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { ApiError, useBoolean } from '#imports'
import { tron } from '@/utils/tron-v2'
import lang from '~/components/lang';

const props = defineProps<{
  refresh?: () => void
}>()

type TDetail = Api.GetDataItem<typeof apis.myPayOrder>

const visible = useBoolean()
const loading = useBoolean(false)
const detail = ref<TDetail>()
const orderPreCancelDetail = computed(() => mutaion.data.value)
const { config } = useAppState()

const mutaion = useMutation({
  mutationKey: [apis.orderPreCancel.id, detail],
  mutationFn: apis.orderPreCancel,
})

async function onCancelOrder() {
  if (!config.value)
    return

  loading.setTrue()
  try {
    const params = {
      toAddress: config.value?.wallet.payment_c2c_address,
      cancelAmount: 1,
      payAddress: detail.value?.pay_address,
    }

    const trx = await tron.sendTrx(params)
    const signedTx = await tron.tronWeb?.trx.sign(trx)
    if (!signedTx)
      throw new Error($t('DBzBcZcNmy4A7uPRj4Fdu'))

    if (!detail.value?.order_no)
      throw new Error($t('UcdGx2YmfIKenryRBra6N'))

    await apis.orderCancelC2C(
      {
        requestBody: {
          order_no: detail.value.order_no,
          signed_txn: signedTx,
        },
      },
      { errorMessageMode: 'none' },
    )

    onCancelOrderPolling()
  }
  catch (error) {
    if (error instanceof String) {
      if (error.includes('balance is not sufficient'))
        aMessage.error($t('smItyVQaod8JL2iUWmxQR'))
      else if (error.includes('Confirmation declined by user'))
        aMessage.error($t('Hk5eWgjl88eEeU0VPuaRz'))
      else
        aMessage.error(error)
    }

    if (error instanceof ApiError) {
      if (error.code === 12006)
        props?.refresh()
    }
    if (error instanceof Error)
      aMessage.error(error.message)
    loading.setFalse()
  }
}

async function onCancelOrderPolling() {
  const { pay_address, order_no } = unref(detail)
  if (!pay_address || !order_no)
    return
  try {
    const cancelDetail = await loop()
    visible.setFalse()
    if (cancelDetail?.status === 12) {
      aMessage.success($t('-1Clwz7kr3msBeSntLihX'))
      onRrefresh()
      return
    }
    if (cancelDetail?.status === 11) {
      aMessage.info(
        `${$t('-1Clwz7kr3msBeSntLihX')}, ${$t('5qh1mLKZJRhuN2jfuToms')}`,
      )
      return
    }
    aMessage.error($t('5qh1mLKZJRhuN2jfuToms'))
    loading.setFalse()
  }
  catch (e) {
    if (e instanceof ApiError)
      aMessage.error(e.message)
  }
  finally {
    loading.setFalse()
  }
}

async function loop() {
  const { pay_address, order_no } = unref(detail)
  for (let i = 0; i <= 14; i++) {
    const res = await apis.orderCancelQuery(
      {
        order_no,
        trx_address: pay_address,
      },
      {
        errorMessageMode: 'none',
        resultType: 'api',
        checkCode: false,
      },
    )
    if (res.code === 17019)
      await sleep(2000)

    if (res.code === 0)
      return res.data

    if (res.code !== 0 && res.code !== 17019)
      throw new ApiError($t('gUjfxWhaElLSefjpXi9rQ'))
  }
}

function onRrefresh() {
  props?.refresh()
}

function open(record: TDetail) {
  detail.value = unref(record)
  visible.setTrue()
  if (detail.value.is_split) {
    mutaion.mutateAsync({
      requestBody: {
        order_no: record.order_no,
        pay_address: record.pay_address,
      },
    })
  }
}

defineExpose({
  open,
  close: visible.setFalse,
})
</script>

<template>
  <AModal
    v-model:open="visible.is"
    wrapClassName="ant-cover__basic-modal"
    :cancelText="$t('lHna6B9mC7xZwMjmQ7En')"
    :footer="false"
    :title="$t('KDyDZExlExDP03YbLQy-p')"
    :maskClosable="false"
    destroyOnClose
    :closable="false"
    @cancel="visible.setFalse()"
  >
    <div class="text-center">
      <div class="mb-20px text-text text-xl lt-tabletl:text-14px" >
        <p :class="{ 'text-left': detail?.is_split }">{{ $t('rtnmJ6y2ViobMdeyka6lC') }}</p>
        <div v-if="detail?.is_split" class="grid grid-cols-[1fr_auto] justify-items-start" :class="{'!grid-cols-2' : currentLocale === 'zh'}">
          <span>{{ $t("EKNvtE7oq0VAHhL2UJ3l_") }} :</span>
          <span>
            {{ formatAmount(orderPreCancelDetail?.frozen_resource_value, 0) }}
            {{ detail.resource_type === 1 ? $t("Mqe_hlD_yGPsAMB9oaO1D") : $t('xo7kGD9R2fV50wOIihlhL') }}
          </span>
          <span>{{ $t("GDebFyoqptFTrdhjj_F1u") }} :</span>
          <span>{{ formatAmount(orderPreCancelDetail?.refund_amount, 0) }} TRX</span>
        </div>
      </div>

      <div class="flex gap-20px">
        <AButton class="cancel-btn flex-1" :loading="loading.is" data-test-id="j2dz" @click="visible.setFalse()">{{ $t('S4xAz2Xg2jnP2zdK9zoXv') }}</AButton>
        <AButton class="comfirm-btn flex-1" :loading="loading.is" data-test-id="bslv" @click="onCancelOrder">{{ $t('UENBgObobQIqSj65k6uBN') }}</AButton>
      </div>
    </div>
  </AModal>
</template>
