export { useResource }

function useResource() {
  const resourceType = [
    {
      label: 'IgN1EoVRFTDyCVLbFG-oT',
      type: 'BANDWIDTH',
      value: 0,
    },
    {
      label: 'YUG0RoDj9Kcw8PtVRPR9Y',
      type: 'ENERGY',
      value: 1,
    },
  ]

  return reactive({
    resourceType,
  })
}
