<script lang="ts" setup>
import { reactiveComputed, useEventBus } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import { tron } from '~/utils/tron-v2'
import { useBandwidthSurplus } from '~/composables/tron/useBandwidthSurplus'

const { account: tronAccount, isConnected } = tronComp.useAccountCurrent()
const isShow = ref(false)
function onToggle() {
  isShow.value = !isShow.value
}
const walletData = reactiveComputed(() => {
  const data = {
    balance: tronAccount?.balance?.trx ?? 0,
    frozenBalanceV2: 0,
    trxBalanceAll: 0,
    netSurplus: 0,
    energySurplus: 0,
    energyLimit: 0,
    freeNetLimit: 0,
    netLimit: 0,
  }

  const { resource, frozenV2, account_resource } = tronAccount
  if (!resource || !frozenV2 || !account_resource)
    return data

  const frozenEnergyBalance = fromSun(frozenV2?.[1]?.amount)
  const frozenBandwidthBalance = fromSun(frozenV2?.[0]?.amount)

  const delegatedFrozenBalanceForEnergy = fromSun(
    account_resource?.delegated_frozenV2_balance_for_energy,
  )

  const delegatedFrozenBalanceForBandwidth = fromSun(
    tronAccount?.delegated_frozenV2_balance_for_bandwidth,
  )

  data.frozenBalanceV2
    = frozenEnergyBalance
    + frozenBandwidthBalance
    + delegatedFrozenBalanceForEnergy
    + delegatedFrozenBalanceForBandwidth

  const frozenV2Conut = frozenEnergyBalance + frozenBandwidthBalance
  let unfreezeAmount = 0

  if ('unfrozenV2' in tronAccount) {
    tronAccount.unfrozenV2?.forEach((i: any) => {
      if ('unfreeze_amount' in i)
        unfreezeAmount += i.unfreeze_amount
    })
  }

  data.trxBalanceAll
    = data.balance
    + frozenV2Conut
    + fromSun(unfreezeAmount)
    + delegatedFrozenBalanceForEnergy
    + delegatedFrozenBalanceForBandwidth

  data.netSurplus
    = filterNumber(resource.freeNetLimit)
    + filterNumber(resource?.NetLimit)
    - filterNumber(resource?.freeNetUsed)
    - filterNumber(resource?.NetUsed)

  data.energySurplus
    = filterNumber(resource?.EnergyLimit) - filterNumber(resource?.EnergyUsed)
  data.energyLimit = filterNumber(resource?.EnergyLimit)
  data.freeNetLimit = filterNumber(resource?.freeNetLimit)
  data.netLimit = filterNumber(resource?.NetLimit)

  return data
})

const energeData = reactiveComputed(() => {
  const data = {
    energySurplus: 0,
    energyLimit: 0,
    canDelegatedForEnergy: 0,
    delegatedForEnergy: 0,
    acquiredDelegatedForEnergy: 0,

  }

  const { resource, delegable, account_resource } = tronAccount
  if (!resource || !delegable || !account_resource)
    return data

  const canDelegatedBalanceForEnergy = fromSun(delegable.energy)
  const acquiredDelegatedFrozenBalanceForEnergy = fromSun(
    account_resource?.acquired_delegated_frozenV2_balance_for_energy,
  )

  data.canDelegatedForEnergy
    = canDelegatedBalanceForEnergy * filterNumber(resource?.energyUnitPrice)
  data.delegatedForEnergy = fromSun(filterNumber(delegable?.energy) + filterNumber(delegable?.bandwidth))

  data.acquiredDelegatedForEnergy
    = acquiredDelegatedFrozenBalanceForEnergy
    * filterNumber(resource?.energyUnitPrice)

  data.energySurplus
    = filterNumber(tronAccount?.resource?.EnergyLimit) - filterNumber(tronAccount?.resource?.EnergyUsed)
  data.energyLimit = filterNumber(resource?.EnergyLimit)

  return data
})

const bandwidthData = reactiveComputed(() => {
  const data = {
    freeNetLimit: 0,
    netLimit: 0,
    netSurplus: unref(useBandwidthSurplus),
    canDelegatedForBandwidth: 0,
    delegatedForBandwidth: 0,
    acquiredDelegatedForBandwidth: 0,
  }

  const { resource, account_resource, delegable } = tronAccount
  if (!resource || !account_resource || !delegable)
    return data

  const canDelegatedBalanceForBandwidth = fromSun(delegable.bandwidth)
  const delegatedFrozenBalanceForBandwidth = fromSun(
    tronAccount?.delegated_frozenV2_balance_for_bandwidth,
  )
  const acquiredDelegatedFrozenBalanceForBandwidth = fromSun(
    tronAccount?.acquired_delegated_frozenV2_balance_for_bandwidth,
  )
  data.canDelegatedForBandwidth
    = canDelegatedBalanceForBandwidth * filterNumber(resource?.netUnitPrice)
  data.delegatedForBandwidth
    = delegatedFrozenBalanceForBandwidth * filterNumber(resource?.netUnitPrice)

  data.delegatedForBandwidth
    = acquiredDelegatedFrozenBalanceForBandwidth
    * filterNumber(resource?.netUnitPrice)
  data.freeNetLimit = filterNumber(resource?.freeNetLimit)
  data.netLimit = filterNumber(resource?.NetLimit)
  return data
})

//  可投票数
const { data: tronVoteData, refetch } = useQuery({
  queryKey: [tronAccount.address],
  enabled: isConnected,
  queryFn: () => tron.getAccountResources(tronAccount.address),
})

const votableNumber = computed(() => {
  const voteTotal = tronVoteData.value?.tronPowerLimit ?? 0
  const voteUsedStic = tronVoteData.value?.tronPowerUsed ?? 0

  return voteTotal - voteUsedStic
})
function fromSun(value: number | undefined) {
  return tron.fromSun(value ?? 0)
}
function filterNumber(value: number | undefined) {
  return value ?? 0
}
function fixedThree(val: number) {
  return formatAmount(val, 3)
}
function fixedZero(val: number) {
  return formatAmount(val, 0)
}

const bus = useEventBus<string>('SWITCH-WALLET')

bus.on(() => {
  refetch()
})
</script>

<template>
  <div class="wallet-container">
    <template v-if="$bp.tabletl">
      <div v-if="isConnected" class="wallet-box">
        <div class="header">
          <span class="p-14px ">
            {{ tronAccount.address }}
          </span>
        </div>
        <div class="statistics text-center">
          <span class="text-[20px] text-text-light font-bold">{{
            fixedThree(walletData.trxBalanceAll)
          }}</span>
          <span class="text-[16px] text-text-light font-bold"> TRX</span> /
          <span class="text-text text-14px">
            {{ $t("i9OSa300ZXceWOm4M4l2k") }}
            <span>
              <span> {{ fixedThree(walletData.balance) }} </span>
              TRX
            </span>
          </span>
        </div>

        <div class="box flex justify-between my-[15px] text-[#8098bf]">
          <div class="item">
            <span class="item-title">{{ $t("pbR3BQwnc1fdub1XpWif") }}</span>
            <p class="text-[#dee5ef] mt-[12px] text-12px">
              <span class="text-16px">{{ fixedThree(walletData.frozenBalanceV2) }}</span>
              TRX
            </p>
          </div>
          <div class="item">
            <span class="item-title">{{ $t("fhOwyNvoMUj35Qr7Umwib") }}</span>
            <p class="text-[#dee5ef] mt-[12px] text-12px">
              <span class="text-16px">{{ fixedZero(energeData.delegatedForEnergy) }} </span>
              TRX
            </p>
          </div>
          <div class="item">
            <span class="item-title">{{ $t("nk4Ms5pcWrfaMskO9uU27") }}</span>
            <p class="text-[#dee5ef] mt-[12px] ">
              {{ formatAmount(votableNumber, 2) }}
            </p>
          </div>
        </div>

        <div class="box2">
          <div class="item">
            <span class="item-title text-18px">
              {{ $t("YUG0RoDj9Kcw8PtVRPR9Y") }}</span>
            <div class="number">
              {{ fixedZero(energeData.energySurplus) }}
              / {{ fixedZero(energeData.energyLimit) }}
            </div>
            <AProgress
              :showInfo="false"
              :percent="energeData.energySurplus / energeData.energyLimit * 100"
              class="m-0 lh-0 "
              size="small"
            />
          </div>

          <div class="item">
            <span class="item-title text-18px">{{ $t("IgN1EoVRFTDyCVLbFG-oT") }}</span>
            <div class="number">
              {{ fixedZero(bandwidthData.netSurplus) }}
              /
              {{
                fixedZero(bandwidthData.freeNetLimit + bandwidthData.netLimit)
              }}
            </div>
            <AProgress
              :showInfo="false"
              :percent="
                bandwidthData.netSurplus / (bandwidthData.freeNetLimit + bandwidthData.netLimit) * 100
              "
              class="m-0 lh-0"
              size="small"
            />
          </div>
        </div>
      </div>
    </template>

    <div v-else-if="isConnected" class="wallet-mobile-box">
      <div class="header">
        <span class="text-white">
          {{ desensitization(tronAccount.address, 10, -10) }}
        </span>
        <i class="i-cryptocurrency-color:trx text-xl align-middle  mt-12px" />
      </div>
      <div class="flex-between items-end px-22px py-10px">
        <div class="info">
          <div class="flex-between" data-test-id="xijf" @click="onToggle">
            <span>{{ $t("YUG0RoDj9Kcw8PtVRPR9Y") }}：
              {{ fixedZero(energeData.energySurplus) }} / {{ fixedZero(energeData.energyLimit) }}</span>
            <i class="icon i-ep:arrow-down text-[#8098bf] text-18px" :class="isShow ? 'flipy' : ''" />
          </div>
          <div v-show="isShow" class="flex-between">
            <span>
              {{ $t("IgN1EoVRFTDyCVLbFG-oT") }}：
              {{ fixedZero(bandwidthData.netSurplus) }}
              / {{ fixedZero(bandwidthData.freeNetLimit + bandwidthData.netLimit) }}
            </span>
            <i class="icon i-local:wallet text-28px" />
          </div>
          <div v-show="isShow">
            {{ $t('i9OSa300ZXceWOm4M4l2k') }} {{ fixedThree(walletData.balance) }} TRX
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media bp-lt-mobile {
  .wallet-container {
    --uno: 'mt-16px';

    .wallet-box {
      --uno: 'w-100%';

      .header {
      }

      .statistics {
      }

      .box {
      }

      .box2 {
      }
    }
  }
}

.wallet-box {
  --uno: 'p-30px border-rd-30px text-primary';

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  background: linear-gradient(180deg, #2e3f74, #232c4f);

  .header {
    height: 50px;
    padding: 10px;
    margin-bottom: 10px;
    line-height: 30px;
    background: #202c52;
    border-radius: 10px;

  }

  .box {
    .item {
      .item-title {
        --uno: 'text-primary text-16px';
      }
    }
  }

  .box2 {
    --uno: 'flex justify-between gap-x-[19px] text-[18px] pt-[12px] relative';

    &::before{
      position: absolute;
      top: -2px;
      width: 100%;
      height: 1px;
      content: '';
      background: linear-gradient(to right,#13192e 0%,#13192e 50%,transparent 50%) repeat-x;
      background-size: 8px 1px;
    }

    .item {
      --uno: 'w-50%';

      .item-title {
        --uno: 'text-16px text-primary';
      }

      .number {
        --uno: 'text-[#e2e8f2] py-[7px] text-16px whitespace-nowrap';
      }
    }
  }
}

.wallet {
  --uno: 'flex items-center justify-center border-rd-30px p-30px cursor-pointer';

  background: linear-gradient(180deg, #2e3f74, #232c4f);

  span {
    --uno: 'ml-10px text-22px text-[--first-text]';
  }
}

.wallet-mobile-box {
  --uno: 'border-rd-10px text-[#e2e8f2] text-14px ';

  // background: linear-gradient(180deg, #2e3f74, #232c4f);
  background: #2A3968;

  .header {
    --uno: 'px-17px  flex justify-between h-44px lh-44px';

    background: linear-gradient(180deg, #31447E, #253258);
    border-radius: 10px 10px 0 0;
  }

  .info {
    width: 100%;
    line-height: 27px;
  }

  .flipy {
    transition: transform 0.3s;
    transform: scaleY(-1);
  }
}

:deep(.ant-progress-inner) {
  background-color: #8098bf;
}

:deep(.ant-progress-text) {
  display: none;
}

:deep(.ant-progress.ant-progress-status-success .ant-progress-bg){
  background-color: #04b3a5;
}
</style>
