<script setup lang="ts">
import { reactiveComputed, useVModel } from '@vueuse/core'
import { TronRes } from '~/utils/tron-v2'
import { useResource } from '~/composables/tron'
import { useSwichLocale } from '~/locales'
import { vAntdInputClosable } from '~/directives'

const props = withDefaults(defineProps<{
  modelValue: number | null | undefined
  shortcut: number[]
  resourceType?: number
  min: number
  max: number
  mode?: 'b2c' | 'c2c'
}>(), {
  resourceType: TronRes.ENERGY,
  mode: 'b2c',
})

const emits = defineEmits(['blur', 'update:modelValue', 'changeEnergyUnit', 'onShortcutChange'])

const { locale } = useSwichLocale()
const { config } = useAppState()
const { resourceType: ResourceType } = useResource()
const resourceValue = useVModel(props, 'modelValue', emits)
const isPenNumber = useBoolean()

const data = reactiveComputed(() => {
  return {
    min: props.min,
    max: props.max,
    resourceType: props.resourceType,
    shortcut: props.shortcut,
  }
})

const haveTokenEnergyUsed = computed(() => config.value?.estimate.have_token_energy_used ?? 32000)

// 乘基
const baseNumber = computed(() => {
  const haveTokenEnergyUsed = config.value?.estimate.have_token_energy_used ?? 32000
  const orderMinEnergy = config.value?.order.energy_min_value ?? 32000
  if (times.value === 1 && haveTokenEnergyUsed < orderMinEnergy && props.mode === 'b2c')
    return orderMinEnergy
  return haveTokenEnergyUsed
})

function increaseNumber(index: number) {
  const _maxRentable = data.max ?? 10 ** 9
  const nextRentable = (resourceValue.value ?? 0) + data.shortcut[index]
  resourceValue.value = Math.min(_maxRentable, nextRentable)
  emits('onShortcutChange')
}

const { initData, increase, times, onChange, maxTiems } = shortcutTimesData()

function onClear() {
  times.value = null
  resourceValue.value = null
}

function shortcutTimesData() {
  let initData = [1, 2, 5, 10]
  if (props.mode === 'c2c')
    initData = [2, 5, 10, 20]

  const times = ref<number | null>(0)

  const increase = (index: number) => {
    times.value = initData[index]
    const _maxRentable = data.max ?? 10 ** 9

    const nextRentable = initData[index] * toValue(baseNumber)
    resourceValue.value = Math.min(_maxRentable, nextRentable)
    emits('onShortcutChange')
  }

  // 最大笔数还是按照used算
  const maxTiems = computed(() => Math.floor(data.max / haveTokenEnergyUsed.value))

  const onChange = () => {
    if (Number(times.value) > maxTiems.value) {
      aMessage.error(`${$t('ofj8j5m1qH1Uyn4HVvHf9')} ${maxTiems.value}${$t('qaIqntWjsgSwMa_0bcQI')}`)
      return
    }
    const nextRentable = Number(times.value) * toValue(baseNumber)
    const _maxRentable = data.max ?? 10 ** 9

    resourceValue.value = Math.min(_maxRentable, nextRentable)
    emits('onShortcutChange')
  }

  return {
    initData,
    increase,
    times,
    onChange,
    maxTiems,
  }
}

function onSwitch() {
  isPenNumber.setTrue()
  emits('onShortcutChange')
}

watch(() => isPenNumber.is, (val) => {
  emits('changeEnergyUnit', val)
  if (typeof resourceValue.value !== 'number' || !resourceValue.value)
    return

  const frequency = Math.floor(resourceValue.value / toValue(haveTokenEnergyUsed))

  times.value = frequency > 0 ? frequency : 1
  if (props.mode === 'c2c' && times.value === 1)
    // c2c 模式下不能有1笔
    times.value = 2

  resourceValue.value = times.value * toValue(baseNumber)
})

watch(() => data.resourceType, () => {
  isPenNumber.setFalse()
})
</script>

<template>
  <!-- 数量 -->
  <template v-if="!isPenNumber.is">
    <AInputNumber
      :key="data.resourceType"
      v-model:value="resourceValue"
      v-antd-input-closable
      class="input-number none-animation w-100%! flex! flex-1!"
      autocomplete="off"
      :placeholder="`${$t('B4fLmwyj-mNioKbH6IA0A')} ${formatAmount(data.min, 0)} ${data.max == '' ? '' : '~'} ${data.max === '' ? '' : formatAmount(data.max, 0)}`"
      v-bind="inputMoney"
      allowClear
      :controls="false"
      :max="props.max"
      :min="props.min"
      :precision="0"
      @blur="emits('blur')"
    >
      <template #prefix>
        <i v-if="data.resourceType === TronRes.ENERGY" class="i-local:energy-s2 text-[20px]" />
        <i v-else class="i-local:tron-bw2-s2 text-[20px]" />
      </template>

      <template v-if="data.resourceType === TronRes.ENERGY" #addonAfter>
        <div class="flex justify-center h-[36px] items-center cursor-pointer text-[#8098bf] hover:text-[#e2e8f2]" data-test-id="fqfq" @click="onSwitch">
          <span>{{ $t(ResourceType[data.resourceType].label) }}</span>
          <i class="i-local:switch?mask ml1 text-[1.2em] text-inherit" />
        </div>
      </template>
    </AInputNumber>

    <div class="energy-select">
      <div
        v-for="(i, k) of data.shortcut"
        :key="k"
        class="energy-info"
        :data-test-id="`4g8l${k}`"
        @click="increaseNumber(k)"
      >
        <span v-if="locale === 'zh'">+{{ i < 10000 ? i : `${i / 10000}万` }}</span>
        <span v-else>+{{ i < 1000000 ? `${i / 1000}k` : `${i / 1000000}m` }}</span>
      </div>
    </div>
  </template>

  <!-- 笔数 -->
  <template v-else>
    <AInputNumber
      v-model:value="times"
      v-antd-input-closable
      :controls="false"
      class="input-number none-animation flex! w-100%! flex-1!"
      autocomplete="off"
      :max="maxTiems"
      :placeholder="`${$t('B4fLmwyj-mNioKbH6IA0A')}${formatAmount(data.min, 0)}~${formatAmount(data.max, 0)}`"
      v-bind="inputMoney"
      allowClear
      :min="props.mode === 'c2c' ? 2 : 1"
      @blur="onChange"
    >
      <template #prefix>
        <i v-if="data.resourceType === TronRes.ENERGY" class="i-local:energy-s2 text-20px" />
        <i v-else class="i-local:tron-bw2-s2 text-20px" />
      </template>

      <template #addonAfter>
        <div class="flex justify-center h-[36px] items-center cursor-pointer text-[#8098bf] hover:text-[#e2e8f2]" data-test-id="q1jv" @click="isPenNumber.setFalse()">
          <span>{{ $t('lRBiJrKgmRu0x1gg_1OVd') }}</span>
          <i class="i-local:switch?mask ml1 text-[1.2em] text-inherit" />
        </div>
      </template>
    </AInputNumber>

    <div class="energy-select">
      <div
        v-for="(i, k) of initData"
        :key="k"
        class="energy-info"
        data-test-id="7o2g"
        @click="increase(k)"
      >
        <span>{{ `${i} ${$t('qaIqntWjsgSwMa_0bcQI')}` }}</span>
      </div>
    </div>
  </template>
</template>

<style scoped lang="scss">
.energy-select {
  --uno: 'mt-9px text-[14px] flex grow-1 gap-8px text-primary lt-mobilel:text-[12px]';
}

.energy-info {
  --uno: 'flex-center h-34px w-100% cursor-pointer border-solid border-1.5 border-rd-8px hover:border-active hover:text-active lt-mobile:h-28px';

  border-color: rgb(128 152 191 / 50%);
}

:deep(.input-number .ant-input-number-input-wrap)  {
  flex: 1;
}
</style>
