<script setup lang="ts">
import { reactiveComputed } from '@vueuse/core'

const props = withDefaults(defineProps<{
  rentDur: number | null
  rentResValue: number
  durUnit: 'h' | 'd'
  haveTokenEnergyUsed?: number
  notHaveTokenEnergyUsed?: number
}>(), {
})

const isRentDays = computed(() => props.durUnit === 'd' && props.rentDur >= 3)
const transferCount = reactiveComputed(() => {
  const rentResValue = (props.rentResValue || 0) * 10
  console.log('rentResValue', rentResValue)
  const haveU = props.haveTokenEnergyUsed
    ? Math.floor(
      rentResValue
    / props.haveTokenEnergyUsed,
    ) / 10
    : 0
  const notHaveU = props.notHaveTokenEnergyUsed
    ? Math.floor(
      rentResValue
    / props.notHaveTokenEnergyUsed,
    ) / 10
    : 0
  console.log('haveU', haveU)
  console.log('notHaveU', notHaveU)
  return {
    haveU,
    notHaveU,
  }
})
</script>

<template>
  <span class="text-base text-[#8098BF] flex items-center">
    <ATooltip overlayClassName="estimate-tooltip-lkr3">
      <template #title>
        <p class="tooltip-content">
          <template v-if="isRentDays">{{ $t('46wDFv7elBghi1ZMWGuRj', {
            count: transferCount.haveU,
          }) }}
          </template>
          <template v-else>{{ $t('1MIR_9U9_g-lhH398Sz7a', {
            count: transferCount.haveU,
          }) }}
          </template>
        </p>
        <p class="tooltip-content">
          <template v-if="isRentDays">{{ $t('xyVuCWPdrc5XF-ueVcgGs', {
            count: transferCount.notHaveU,
          }) }}
          </template>
          <template v-else>{{ $t('Ab7jLFoHk8KArcZWE_wf-', {
            count: transferCount.notHaveU,
          }) }}
          </template>
        </p>
      </template>
      <i class="i-ri:question-line text-xl" />
    </ATooltip>
    <span class="text-[14px] ">
      <template v-if="isRentDays">{{ $t('cXGrJFFZU6e5tBIu8VH9_', {
        count: transferCount.haveU,
      }) }}
      </template>
      <template v-else>{{ $t('zvshBaWE4UZJw3Crn1vHD', {
        count: transferCount.haveU,
      }) }}</template>
    </span>

  </span>
</template>

<style lang="scss">
// 在中文下 Tooltip 中的内容不换行
:root:lang(zh) .estimate-tooltip-lkr3 {
  max-width: initial;

  .tooltip-content {
    white-space: nowrap;
  }
}
</style>
