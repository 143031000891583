<script setup lang="ts">
import { useActivity } from '~/composables/useActivity'
import { useSwichLocale } from '~/locales'

const { locale } = useSwichLocale()

const { states, onReceive, onRule, receiveList, activityConfig } = useActivity()
</script>

<template>
  <div v-if="states.showActivity" class="activity-main hidden lt-laptopl:block">
    <div class="flex justify-between items-start">
      <img v-if="locale === 'zh'" src="@/assets/imgs/activity-mobile-ch.svg" class="w-190px mt--5px mb--10px ml--8px">
      <img v-else src="@/assets/imgs/activity-mobile-en.svg" class="w-190px mt--5px mb--10px ml--8px">

      <div class="receive-btn">
        <div
          v-if="states.freeOrder === 2"
          class="w-100% h-100% py-7px"
          @click="onReceive()"
          data-test-id="gfl7"
        >
          <p class="text-14px text-#dee5ef font-blod">{{ $t('7uFnjhjAwpt7tk0CbhjvF') }}</p>
          <p class="text-[#ffc52f] mt-4px text-12px">{{ $t('7oTIdW2je7YhwIroJKfcC') }}: {{ activityConfig?.available ?? 0 }}</p>
        </div>
        <p v-if="states.freeOrder === 1">{{ $t('sh8Po1QGcmHkDwVjjam2m') }}</p>
      </div>
    </div>
    <p class="lh-20px">
      {{ $t('ujVxMbCptOw13TqduCAn', { limit: (activityConfig?.limit ?? 500) }) }}
      <span class="tips" @click="onRule()" data-test-id="rpf2">{{ $t('y2xHRtiDxd8pAifc5ErvM') }}</span>
    </p>

    <div>
      <ACarousel
        v-if="receiveList"
        autoplay
        :autoplaySpeed="2000" :slidesToShow="1" :dots="false" dotPosition="right"
        class="list-box"
      >
        <div
          v-for="(i, index) in receiveList" :key="index"
          class="!flex-between px-12px my-8px h-17px"
        >
          <p>
            {{ $d(i.create_time * 1000, "hm") }}
            <span class="ml-12px">{{ desensitization(i.receive_address, 4, -4) }} {{ $t('is6Kqxb54vyqpJi2sVuNt') }}</span>
          </p>
          <a class="hover:underline !text-[#ffc52f]" :href="`https://tronscan.org/#/transaction/${i.frozen_tx_id}`" target="_blank" data-test-id="KCQZ">{{ $t('5wmY4C7tFhssJVxS_0i1f') }}</a>
        </div>
      </ACarousel>
    </div>
  </div>
</template>

<style scoped lang="scss">
@media bp-at-tabletl, bp-at-laptop, bp-lt-tabletl {
  .activity-main{
    --uno: 'p-16px border-rd-16px text-#dee5ef text-13px mb-0 lt-tabletl:border-rd-10px';

    background: linear-gradient(90deg, #2680EB 50%, #07939A);

    .receive-btn{
      --uno: 'bg-#266ef1 border-rd-10px w-110px h-48px text-center flex items-center justify-center';

      box-shadow: inset 0 -4px 0 rgb(31 92 202);
    }

    .tips{
      --uno: 'relative text-[#ffc52f] cursor-pointer pt-10px pb-15px';

      z-index: 99999;
    }

    .list-box{
      --uno: 'mt-7px border-rd-12px text-14px text-#dee5ef';

      height: 34px;
      line-height: 30px;
      background: rgb(255 255 255 / 20%);
    }

  }
}
</style>
